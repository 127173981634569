import { AES, enc } from 'crypto-js'

export const decryptWithAES = (ciphertext, passphrase) => {
  try {
    const bytes = AES.decrypt(ciphertext, passphrase)
    const content = bytes.toString(enc.Utf8)
    const isEmpty = content != null
      ? content.trim() === ''
      : false
    return !isEmpty
      ? content
      : null
  } catch (e) {
    return null
  }
}

export const encryptWithAES = (content, passphrase) => {
  return AES.encrypt(content, passphrase).toString()
}
