import { useCallback, useState } from 'react'
import styled from 'styled-components'

import Button from '../Button'
import Input from '../Input'
import Form from '../Form'

const StyledForm = styled(Form)`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`

const AuthForm = (props) => {
  const { storePassphrase } = props
  const [passphrase, setPassphrase] = useState('')
  const handleUnlock = useCallback(() => {
    storePassphrase(passphrase)
  }, [passphrase])
  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    handleUnlock()
  }, [])
  const handlePassphraseChange = useCallback((event) => {
    const { value: _value } = event.target
    const value = _value.toLowerCase()
    setPassphrase(value)
  }, [])
  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <Input onChange={handlePassphraseChange} placeholder='Password' password />
        <Button type='submit' onClick={handleUnlock}>Unlock</Button>
      </StyledForm>
    </>
  )
}

export default AuthForm
