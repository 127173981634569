import { useState, useEffect } from 'react'
import { useSearchParam, useLocalStorage } from 'react-use'
import useSound from 'use-sound'

import ToolPage from './pages/ToolPage'
import InfoPage from './pages/InfoPage'
import HackerLayout from './layouts/HackerLayout'

import { decryptWithAES } from './libraries/crypto'
import { parseStrToJson } from './libraries/json'

import errorBeepSound from './sounds/error-beep.mp3'
import ahahahSound from './sounds/ahahah.mp3'
import AsciiAhAhAh from './components/AsciiAhAhAh'

const VIEW_TOOLS = 'VIEW_TOOLS'
const VIEW_INFO = 'VIEW_INFO'

const views = [VIEW_INFO, VIEW_TOOLS]

const { REACT_APP_VAULTS } = process.env
const vaults = REACT_APP_VAULTS.split(',')

function App () {
  const view = useSearchParam('view') || 0
  const [content, setContent] = useState()
  const [failCount, setFailCount] = useState(0)
  const [showAhahah, setShowAhahah] = useState(false)
  const [passphrase, storePassphrase, removePassphrase] = useLocalStorage('passphrase')
  const [playError] = useSound(errorBeepSound)
  const [playAhahah, { stop: stopAhahah }] = useSound(ahahahSound)
  useEffect(() => {
    const validAttempt = passphrase != null && passphrase?.trim?.() !== ''
    if (validAttempt) {
      const decrypted = vaults.reduce((acc, blob) => {
        const content = decryptWithAES(blob, passphrase)
        return content != null
          ? content
          : acc
      }, null)
      if (decrypted == null) {
        setFailCount(fc => fc + 1)
        if (failCount >= 2) {
          stopAhahah()
          playAhahah()
          setShowAhahah(true)
        } else {
          playError()
        }
      } else {
        stopAhahah()
        setFailCount(0)
        setShowAhahah(false)
      }
      setContent(parseStrToJson(decrypted))
    } else {
      setContent(null)
    }
  }, [passphrase, vaults])
  return (
    <HackerLayout>
      {views[view] === VIEW_TOOLS
        ? <ToolPage />
        : (
          <InfoPage
            content={content}
            storePassphrase={storePassphrase}
            removePassphrase={removePassphrase}
          >
            <AsciiAhAhAh show={showAhahah} />
          </InfoPage>
          )}
    </HackerLayout>
  )
}

export default App
