import { useCallback } from 'react'
import useSound from 'use-sound'

import AsciiPlayer from '../AsciiPlayer'

import asciiMonaLisaFrames from '../../data/ascii-mona-lisa.txt'
import puffSound from '../../sounds/puff.mp3'

const AsciiMonaLisa = () => {
  const [play] = useSound(puffSound, { volume: 0.025 })
  const handleFrame = useCallback((frameIndex) => {
    if (frameIndex === 0) play()
  }, [play])
  return (
    <AsciiPlayer
      sources={asciiMonaLisaFrames}
      onFrame={handleFrame}
    />
  )
}

export default AsciiMonaLisa
