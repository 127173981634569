import styled from 'styled-components'

const Main = styled.main`
  display: flex;
  background-color: black;
  color: lime;
  width: 100vw;
  min-height: 100vh;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-family: 'Courier', monospace;
`
const HackerLayout = (props) => {
  const { children } = props
  return (
    <Main>
      {children}
    </Main>
  )
}

export default HackerLayout
