import { useEffect, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useInterval } from 'react-use'
import useSound from 'use-sound'
import Helmet from 'react-helmet'

import AuthForm from '../../components/AuthForm'
import Button from '../../components/Button'
import UnorderedList from '../../components/UnorderedList'
import AsciiMonaLisa from '../../components/AsciiMonaLisa'

import Flex from '../../components/Flex'

import beepSound from '../../sounds/short-beep.mp3'
import AsciiPlayer from '../../components/AsciiPlayer'

import asciiUnicorn from '../../data/ascii-unicorn-frames.txt'
import asciiSpaceInvaders from '../../data/ascii-space-invaders.txt'

const WindowRightAction = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-width: 0;
  border-left-width: 2px;
`

const WindowWrap = styled.div`
  border: 2px solid lime;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props => props.maxWidth && css`max-width: ${props.maxWidth};`}
`

const WindowTop = styled.div`
  border-bottom: 2px solid lime;
  padding: 0.5rem;
  position: relative;
`

const WindowContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const MaxWidth = styled.div`
  max-width: ${props => props.width};
`

const Center = styled.div`
  align-self: center;
`

const Padding = styled.div`
  padding: ${props => props.padding};
`

const AsciiArtTable = {
  mona: AsciiMonaLisa,
  unicorn: () => <AsciiPlayer sources={asciiUnicorn} />,
  spaceInvaders: () => <AsciiPlayer sources={asciiSpaceInvaders} />
}

const InfoPage = (props) => {
  const {
    storePassphrase,
    removePassphrase,
    content,
    children,
    loading = false
  } = props
  const {
    links = [],
    messages = [],
    title = '',
    name = 'NPT',
    faviconSrc = 'favicon.ico',
    asciiIndex
  } = content || {}
  const [rowIndex, setRowIndex] = useState()
  const [animate, shouldAnimate] = useState(false)
  const [play] = useSound(beepSound, { volume: 0.15 })
  const isSignedIn = content != null && loading === false
  const contentWidth = isSignedIn
    ? null
    : '250px'
  const contentLength = Math.max(links.length, messages.length)

  const AsciiArt = AsciiArtTable?.[asciiIndex] || AsciiArtTable.spaceInvaders
  const handleSignOut = useCallback(() => {
    removePassphrase()
  }, [])
  useEffect(() => {
    if (rowIndex > contentLength - 1) shouldAnimate(false)
  }, [rowIndex])
  useEffect(() => {
    if (isSignedIn === true) {
      shouldAnimate(true)
    }
    return () => {
      setRowIndex(0)
      shouldAnimate(false)
    }
  }, [isSignedIn])
  const incrementRowIndex = (ri) => ri < contentLength
    ? ri + 1
    : ri
  useInterval(() => {
    if (!animate) return
    setRowIndex(ri => ri != null
      ? incrementRowIndex(ri)
      : 0
    )
    play()
  }, animate ? 100 : null)

  const windowTitle = `${name} $~`
  return (
    <WindowWrap maxWidth='640px'>
      <Helmet>
        <title>{windowTitle}</title>
        <link
          rel='icon' href={faviconSrc}
        />
      </Helmet>
      <WindowTop>{windowTitle}
        {content && <WindowRightAction onClick={handleSignOut}>x</WindowRightAction>}
      </WindowTop>
      <Center>
        <Padding padding='1rem'>
          <MaxWidth width={contentWidth}>
            <WindowContent>
              {children}
              {content && (
                <Flex spaceBetween column>
                  <AsciiArt />
                  <p>{title}</p>
                  {links.length > 0 && (
                    <UnorderedList symbol='"=> "'>
                      {links.slice(0, rowIndex).map((item, i) => (
                        <li key={i}>
                          <a href={item.link} target='_blank' rel='noreferrer'>{item.title}</a>
                        </li>
                      ))}
                    </UnorderedList>
                  )}
                  {messages.length > 0 && (
                    <UnorderedList>
                      {messages.slice(0, rowIndex).map((message, i) => <li key={i}>{message}</li>)}
                    </UnorderedList>
                  )}
                </Flex>
              )}
              {!content && (
                <Flex spaceBetween column height='150px'>
                  <p>Enter password</p>
                  <AuthForm storePassphrase={storePassphrase} />
                </Flex>
              )}
            </WindowContent>
          </MaxWidth>
        </Padding>
      </Center>
    </WindowWrap>
  )
}

export default InfoPage
