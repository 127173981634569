import { useCallback } from 'react'
import styled from 'styled-components'
import useSound from 'use-sound'

import mouseClickSound from '../../sounds/mouse-click.mp3'

const ButtonElem = styled.button`
  border: 1px solid lime;
  padding: 0.5rem 1rem;
  color: lime;
  background-color: black;
  cursor: pointer;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  &:focus {
    border-color: limegreen;
  }
`

const Button = (props) => {
  const { onClick, silent = false, ...rest } = props
  const [play] = useSound(mouseClickSound)
  const handleClick = useCallback((event) => {
    onClick?.(event)
    !silent && play()
  }, [onClick, play, silent])
  return (
    <ButtonElem onClick={handleClick} {...rest} />
  )
}

export default Button
