import { useCallback, useState } from 'react'
import styled from 'styled-components'

import { encryptWithAES, decryptWithAES } from '../../libraries/crypto'

const TextArea = styled.textarea`
  width: 45vw;
  height: 250px;
`

const ToolPage = () => {
  const [inputText, setInputText] = useState('')
  const [cipherText, setCipherText] = useState('')
  const [password, setPassword] = useState('')
  const handleCipherChange = useCallback((event) => {
    const { value } = event.target
    setCipherText(value)
  })
  const handleInputChange = useCallback((event) => {
    const { value } = event.target
    setInputText(value)
  }, [])
  const handlePasswordChange = useCallback((event) => {
    const { value } = event.target
    setPassword(value)
  }, [])

  const handleEncryptClick = useCallback(() => {
    const encrypted = encryptWithAES(inputText, password)
    setCipherText(encrypted)
  }, [inputText, password])
  const handleDecryptClick = useCallback(() => {
    const decrypted = decryptWithAES(cipherText, password)
    setInputText(decrypted)
  }, [cipherText, password])
  return (
    <>
      <label htmlFor='password'>password</label>
      <input type='password' name='password' onChange={handlePasswordChange} />
      <br />
      <label htmlFor='input'>Text</label>
      <TextArea value={inputText} name='input' onChange={handleInputChange} />
      <br />
      <label htmlFor='encrypted'>Encrypted</label>
      <TextArea value={cipherText} name='encrypted' onChange={handleCipherChange} />
      <br />
      <button onClick={handleEncryptClick}>Encrypt</button>
      <button onClick={handleDecryptClick}>Decrypt</button>
    </>
  )
}
export default ToolPage
