import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import useSound from 'use-sound'

import keyboardSound from '../../sounds/keyboard-click.mp3'

const InputElem = styled.input`
  appearance: none;
  border: 1px solid lime;
  font-family: inherit;
  font-size: inherit;
  ${props => props.password && css`
    -webkit-text-security: square;
  `}
  padding: 0.5rem 1rem;
  color: lime;
  background-color: transparent;
  outline: none;
  ::placeholder {
    color: darkgreen;
  }
  &:focus {
    border-color: limegreen;
  }
`

const Input = (props) => {
  const { onChange, silent = false, ...rest } = props
  const [play] = useSound(keyboardSound, { volume: 0.5 })
  const handleChange = useCallback((event) => {
    onChange?.(event)
    !silent && play()
  }, [onChange, play, silent])
  return <InputElem onChange={handleChange} {...rest} />
}

export default Input
