import { useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import styled from 'styled-components'

const AsciiArt = styled.code`
  white-space: pre;
  text-align: left;
  margin: 0;
  letter-spacing: -0.2em;
  line-height: 0.8em;
  display: ${props => !props.show
    ? 'none'
    : 'initial'};
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const trimFrame = (frame) => {
  const lines = frame.split('\n')
  const formatted = lines.map(line => line.trimRight())
  return formatted.join('\n')
}

const trimFrames = (frames) => {
  return frames.map(trimFrame)
}

const wrap = obj => Array.isArray(obj)
  ? obj
  : [obj]

const getFrames = frames => {
  const subframes = frames.map(frame => frame.split('\n\n\n'))
  return subframes.reduce((acc, cur) => [...acc, ...cur], [])
}

const AsciiPlayer = (props) => {
  const {
    interval = 1000,
    sources: _sources = [],
    onFrame,
    show = true,
    ...rest
  } = props
  const [loading, setLoading] = useState()
  const [frames, setFrames] = useState([])
  const [frameIndex, setFrameIndex] = useState(0)
  useInterval(() => {
    setFrameIndex(fi => (fi + 1) % frames.length)
    onFrame?.(frameIndex)
  }, interval)
  useEffect(() => {
    const { fetch } = window
    setLoading(true)
    const sources = wrap(_sources)
    const promises = sources.map((path) => fetch(path))
    Promise.all(promises)
      .then((responses) => Promise.all(responses.map(response => response.text())))
      .then((_frames) => {
        const frames = getFrames(_frames)
        const trimmed = trimFrames(frames)
        setFrames(trimmed)
        setLoading(false)
      })
    return () => setLoading()
  }, [])
  return (
    <Center>
      <AsciiArt show={show} {...rest}>{
      loading === false && show
        ? frames[frameIndex]
        : 'Loading..'
      }
      </AsciiArt>
    </Center>
  )
}

export default AsciiPlayer
